/* eslint-disable */
import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import theme from '../../theme';

import SocialMediaIcons from '../SocialMediaIcons';

const Column1 = [
    {
        label: 'Главная',
        href: '/',
    },
    {
        label: 'Мероприятия',
        href: '/events',
    },
    {
        label: 'Библиотека',
        href: '/library',
    },
];
const Column2 = [
    {
        label: 'Спецпроекты',
        href: '/specprojects',
    },
    {
        label: 'Спикеры',
        href: '/speakers',
    },
    // {
    //     label: 'Партнерам',
    //     href: '/for-partners',
    // },
];

const CustomLink = styled(Link)({
    padding: '5px 0',
    textDecoration: 'none',
    fontWeight: 700,
    cursor: 'pointer',
    color: theme.palette.primary.light,
});

const FlexBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
});

const CustomTypography = styled(Typography)({
    cursor: 'pointer',
    color: theme.palette.primary.light,
    padding: '5px 0',
    fontWeight: 700,
});

const Footer = () => {
    return (
        // eslint-disable-next-line react/jsx-filename-extension
        <>
            <Box
                sx={{
                    background: '#F0F0F0',
                    pt: 1,
                    pb: 1,
                }}
            >
                <Container sx={{ textAlign: 'center' }}>
                    <a
                        target="_blank" style={{ color: '#5C5C5C', fontWeight: 700 }}
                        href="https://drive.google.com/file/d/1jFbd3z2OYSb9dY9rpy3wX6DSy2X4jogU/view?usp=sharing"
                        
                    >
                        Политика в отношении обработки персональных данных
                    </a>
                </Container>
            </Box>
            <Box
                sx={{
                    background: theme.palette.primary.dark,
                    pt: 6,
                    pb: 6,
                }}
            >
                <Container
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: 'space-between',
                        alignItems: { xs: 'center', md: 'left' },
                    }}
                >
                    <FlexBox
                        sx={{
                            textAlign: { xs: 'center', md: 'left' },
                        }}
                    >
                        {Column1.map((item) => (
                            <CustomLink to={item.href} key={item.label}>
                                {item.label}
                            </CustomLink>
                        ))}
                    </FlexBox>

                    <FlexBox
                        sx={{
                            textAlign: { xs: 'center', md: 'left' },
                        }}
                    >
                        {Column2.map((item) => (
                            <CustomLink to={item.href} key={item.label}>
                               {item.label}
                            </CustomLink>
                        ))}
                    </FlexBox>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: { xs: 'center', md: 'left' },
                        }}
                    >
                        <CustomTypography>Контакты</CustomTypography>

                        <CustomTypography> +375 152 620 330</CustomTypography>
                        <CustomTypography> +375 29 705 63 62</CustomTypography>
                        <CustomTypography
                            sx={{
                                fontWeight: 300,
                            }}
                        >
                            admin@congress.by
                        </CustomTypography>
                        <CustomTypography
                            sx={{
                                fontWeight: 300,
                            }}
                        >
                            info@congress.by
                        </CustomTypography>
                    </Box>
                    <Box>
                        <SocialMediaIcons fill="white" footer />
                        <Typography sx={{ color: theme.palette.primary.light, padding: '3px 0', textAlign: 'center' }}>
                            © Copyright - АММ.
                        </Typography>
                        <Typography sx={{ color: theme.palette.primary.light, padding: '3px 0', textAlign: 'center' }}>
                            All rights reserved.
                        </Typography>
                        <Typography sx={{ color: theme.palette.primary.light, padding: '3px 0', textAlign: 'center' }}>
                            УНП 591046825
                        </Typography>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default Footer;
